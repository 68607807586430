

const PreFooter = () => {
    return ( 
        <>  
            <div className="wrapper">
                <div className="content-container">
                    <div className="prefooter">
                        <p className="prefooter">                             
                            $MEMOS is a meme coin with no intrinsic value or expectation of financial return. <br />
                            The coin is completely useless and for entertainment purposes only.
                        </p> 
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default PreFooter;